import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Drawer,
    Grid, Paper, Container
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';

import AppContext from '../AppContext'
import Util from './service/Util.js'
import Site from './service/Site.js'
import Cart from "./service/Cart";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import FoodListMenu from "./FoodListMenu";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 1,
        color: 'white',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

class MyAppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            totalCartFoodCount:0,
            totalCartValue:0,
            siteChange:'',
            authChange:'',
            slider:0,
            noappbar: false,
        }
    }
    imagesLoad=[];
    loadNextBackground(){
        if (Site.instance.site.images && Site.instance.site.images.length>0 && this.state.slider<Site.instance.site.images.length) {
            let images = Site.instance.site && Site.instance.site.images ? Site.instance.site.images : [""];
            let el = document.getElementById("mainContainer");
            if (el) el.style.backgroundImage = "url(" + images[this.state.slider] + ")";
            this.setState({slider: (this.state.slider + 1) % Site.instance.site.images.length})
        }
    }
    componentDidMount() {
        Cart.instance.cartSubject.subscribe(()=>{
            this.setState({totalCartValue: Cart.instance.totalCartValue, totalCartFoodCount: Cart.instance.totalCartFoodCount})
        })
        Site.instance.siteSubject.subscribe(()=>{
            this.setState({siteChange: ''})
            if (Site.instance.site && Site.instance.site.images) {
                this.imagesLoad.length = 0;
                this.loadNextBackground();
                Site.instance.site.images.forEach((image) => {
                    let img = new Image();
                    img.src = image
                    this.imagesLoad.push(img);
                });

                if (Site.instance.site.undercnote && Site.instance.site.undercnote=='suspended'){
                    window.location.href='/unavailable.html'
                }
            }
        })
        Site.instance.authSubject.subscribe(()=>{
            this.setState({authChange: ''})
        })
        setInterval(()=>{
            this.loadNextBackground()
        }, 10000)
    }

    selectSite(site){
        localStorage.setItem("sitekey",site.api_key )
        window.location.reload();
    }
    render() {
        let site = Site.instance.site;
        let auth = Site.instance.auth;
        let images = Site.instance.site && Site.instance.site.images ? Site.instance.site.images : [""];
        return (
    <React.Fragment>

    <AppBar position="static"  style={{backgroundColor: "#00000052"}}>
        {!Util.instance.isNative() &&
        Util.instance.isOnAndroid()  &&
        Util.instance.isOnMobile()  &&
        !this.state.noappbar &&
        <Toolbar style={{
            alignItems: 'center',
            backgroundColor: "white",
            fontSize: 12,
            color: "black",
            minHeight: 35,
            padding:0
        }}>
            <div style={{
                display: "table-cell",
                verticalAlign: "middle",
                alignItems: "center",
                textAlign: "center",
                width: "90%",
            }}>
                <a onClick={() => {
                    window.location.href = "#DownloadApp"
                }}> Use Our Android App</a>

            </div>
            <IconButton edge="end" onClick={()=>{
                this.setState({noappbar: true})
            }} style={{padding: 2}}>
                <CloseIcon fontSize="small"/>
            </IconButton>
        </Toolbar>
        }


                        <Toolbar style={{
                            // backgroundImage: "url(" + images[this.state.slider] + ")",
                            // backgroundSize: "cover",
                            alignItems: 'flex-start',
                            WebkitTransition: "background-image 0.9s ease-in-out",
                            transition: "background-image 0.9s ease-in-out",
                        }}>
                            <IconButton edge="start" className={useStyles.menuButton} color="inherit"
                                        aria-label="menu" onClick={()=>{
                                            Cart.instance.showDialogSubject.next({name: "LeftDrawer", show:true})
                            }}>
                                <MenuIcon/>
                            </IconButton>
                            <Typography variant="h6" style={{
                                flexGrow: 1,
                                alignSelf: 'flex-end',
                            }}>
                            </Typography>

                            {Site.instance.isLogin() &&
                            <IconButton aria-label="Logout" color="inherit"
                                        style={{alignItems: "center", display: "flex", fontSize: "large", paddingLeft:4, paddingRight:4}}
                                        onClick={() => {
                                            Site.instance.logout()
                                        }}
                            >
                                <ExitToAppIcon/>
                                <div>Logout</div>
                            </IconButton>
                            }

                                    <IconButton aria-label="Account" color="inherit"
                                                style={{alignItems: "center", display: "flex", fontSize: "large", paddingLeft:4, paddingRight:4}}
                                                onClick={() => {
                                        if (!Site.instance.isLogin())
                                            Cart.instance.showDialogSubject.next({name: "LoginDialog", show: true})
                                        else
                                            Cart.instance.showDialogSubject.next({name: "ProfileDialog", show: true})
                                    }}
                                    >
                                        <PersonOutline/>
                                        <div>{ Site.instance.isLogin()? Site.instance.auth.firstname : "Login/Register"}</div>
                                    </IconButton>
                            {
                                this.state.totalCartFoodCount > 0 &&
                                    <IconButton color="inherit"
                                                style={{alignItems: "center", display: "flex", fontSize: "large", paddingLeft:4, paddingRight:4}}
                                                onClick={() => {
                                                    Cart.instance.showDialogSubject.next({name: "CartDialog", show: true})
                                                }}>
                                        <Badge badgeContent={this.state.totalCartFoodCount} color="secondary" style={{alignItems: "center", display: "flex"}}>
                                            <div style={{paddingRight: 4}}>{Util.instance.formatPrice(this.state.totalCartValue)}</div>
                                            <ShoppingBasketOutlined/>
                                        </Badge>
                                    </IconButton>
                            }
                        </Toolbar>

                    </AppBar>
        {Site.instance.isLogin() && Site.instance.auth.sites &&
        <Drawer anchor="bottom"
                open={this.state.showSites}
                onClose={() => this.setState({showSites: false})}
        >
            <List>
                { Site.instance.auth.sites.map((s)=>
                    <ListItem key={s.api_key} button onClick={() => this.selectSite(s)}>
                        <ListItemText primary={
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{flexGrow: 1}}></div>
                                {localStorage.getItem("sitekey") === s.api_key && <CheckOutlinedIcon color="disabled"/>
                                }
                            </div>}/>
                    </ListItem>
                )}
            </List>
        </Drawer>
        }
    </React.Fragment>
    );
    }
}

export default MyAppBar;
