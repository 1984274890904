import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Button, BottomNavigation
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import Util from './service/Util.js'
import Cart from "./service/Cart";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 1,
        color: 'white',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

class BottomBar extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            totalCartValue:0,
            totalCartFoodCount:0
        }
        Cart.instance.cartSubject.subscribe(()=>{
            console.log("totalcartvalue: " + Cart.instance.totalCartValue);
            this.setState({totalCartValue: Cart.instance.totalCartValue, totalCartFoodCount: Cart.instance.totalCartFoodCount})
        })
    }
    render() {
        let onShowCartDialog = this.props.onShowCartDialog;
        return (
            this.state.totalCartFoodCount>0&&
                    <BottomNavigation
                        style={{
                            width: '100%',
                            position: 'sticky',
                            bottom: 0,
                            zIndex: 200,
                            marginTop: 5,
                            borderTop: "1px dotted red",
                        }}>
                        <Grid justify="center" container
                              spacing={0}
                              direction="row"
                              alignItems="center"
                        >
                            <Badge badgeContent={this.state.totalCartFoodCount} color="secondary"
                                   onClick={() => {
                                       Cart.instance.showDialogSubject.next({name: "CartDialog", show: true})
                                   }}>
                                {Util.instance.formatPrice(this.state.totalCartValue)}
                                <ShoppingBasketOutlined/>
                            </Badge>
                            <Button style={{position: "absolute", right: 0}} onClick={()=>{
                                Cart.instance.showDialogSubject.next({name: "CartDialog", show: true})
                            }}
                                    color={"secondary"}>BASKET < ArrowForwardIosIcon/></Button>
                        </Grid>
                    </BottomNavigation>
        );
    }
}

export default BottomBar;
